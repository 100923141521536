import React from 'react';

const Contact = () => {
   return (
      <section id="contact">
         <div className="section-title">
            <h1>Contact Me</h1>
            <a href="mailto:oscar.silva1251@gmail.com" rel="noopener noreferrer" target="_blank">Say Hello</a>
         </div>
      </section>
   );
}

export default Contact;
