import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ProjectCard from './ProjectCard';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  projectCard: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export default function Projects() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={6} md={4}>
          <ProjectCard className={classes.projectCard} name="Tweets Visualizer" image="t_clone_01.PNG" link="https://twitter-clone-oscar.netlify.app/" technologies={['ExpressJS | NodeJS | Twitter API | Heroku']} description="Web app that allows visualizing Twitter data such as posts, username, images, videos and gifs, based on user input."/>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ProjectCard className={classes.projectCard} name="Geo location app" image="store-locator.PNG" link=" " technologies="JavaScript | Node.js | Geocoding API | MongoDB" description="Web app that accepts a zip code to query data based on Geo Coordinates to show the stores near the location."/>
        </Grid>         
        <Grid item xs={12} sm={6} md={4}>
          <ProjectCard className={classes.projectCard} name="Video app" image="tik-tok-clone.PNG" link="https://osl-tiktok-clone.web.app/" technologies="React | Firebase | Material-UI" description="Web app that shows videos in a TikTok way with snap functionality, song effect and like button."/>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ProjectCard className={classes.projectCard} name="CRUD app" image="don_01.PNG" link="https://donacionesweb.azurewebsites.net/" technologies="ASP.NET Core | Azure | Entity Framework | MSSQL" description="Mobile and web app with authentication and authorization for users to register and log in according to their permissions."/>
        </Grid>       
        <Grid item xs={12} sm={6} md={4}>
          <ProjectCard className={classes.projectCard} name="Blog" image="blog_01.PNG" link=" " technologies="JavaScript | Node.js | Flexbox" description="Personal blog that shows your recent posts, create new blog posts uploading data and images to an API."/>
        </Grid>
      </Grid>
    </div>
  );
}
