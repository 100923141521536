import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    '& button':{
      '& span':{
        fontSize:'15px'
      }
    }
  }
}));

export default function SimpleTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
//
const AntTabs = withStyles({
  indicator: {
    backgroundColor: '#F0600D',
  },
})(Tabs);
//
  return (
    <div className={classes.root}>
      <AntTabs value={value} onChange={handleChange} aria-label="simple tabs example">
        <Tab label="Vortex" {...a11yProps(0)} />
        <Tab label="Grupo MCM" {...a11yProps(1)} />
        <Tab label="Ludicultura" {...a11yProps(2)} />
      </AntTabs>
      <TabPanel value={value} index={0}>
        <h3>Vortex</h3>
        <p className="info">Full Stack Developer<span>&bull;</span> <em className="date">January - June 2021</em></p>
        <ul className="text">
          <li>● Developed an accounting application for internal use with the jamstack architecture.</li>
          <li>● Analyzed the business needs and requirements based on the general idea.</li>
          <li>● Designed the data model, created the database, API endpoints and graphql queries.</li>
          <li>● Secured the API with JWT token.</li>
          <li>● Collaborated in the wireframe design.</li>
          <li>● Implemented the design using mockup files as a front-end developer.</li>
          <li>● Created the different components, url routes and pages fixing bugs and testing usability to ensure an optimized user experience.</li>
        </ul>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <h3>Grupo MCM</h3>
        <p className="info">Software Engineer<span>&bull;</span> <em className="date">July - December 2020</em></p>
        <ul className="text">
          <li>● Analyzed the business needs for developing a web application for a construction company.</li>
          <li>● Proposed a scalable solution to deploy the app with Azure app service and Azure SQL Database.</li>
          <li>● Designed the Entity-Relationship model based on the requirements needed and created the data models using Entity Framework.</li>
          <li>● Developed the application using HTML, CSS, C#, ASP.NET Core.</li>
          <li>● Communicated with the team of engineers, and stakeholders on a weekly basis.</li>
          <li>● Manually tested the app in various browsers to ensure cross-browser compatibility.</li>
        </ul>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <h3>Ludicultura</h3>
        <p className="info">Web Developer<span>&bull;</span> <em className="date">January - June 2020</em></p>
        <ul className="text">
          <li>● Developed a website for a non-profit association using JavaScript, HTML, CSS, Sass and Github.</li>
          <li>● Implemented the design using mockup files as a front- end developer, presenting changes and progress to stakeholders.</li>
        </ul>
      </TabPanel>
    </div>
  );
}
