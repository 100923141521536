import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import GitHubIcon from '@material-ui/icons/GitHub';
import LinkIcon from '@material-ui/icons/Link';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  root: {
    margin:35,
    maxWidth: 345,
    '& p': {
      margin:0,
      fontFamily: 'opensans-regular',
      fontSize: '10px',
      color: '#F0600D',
      fontWeight: '800',
      paddingTop:'8px'
    }
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  cardContentStyle:{
    padding:'16px 16px 8px 16px'
  },
  cardContentText: {
    fontFamily: 'opensans-regular',
    fontSize: '14px',
    color: '#838C95'
  },
  cardHeaderText: {
    font: '25px/30px opensans-bold',
    color: '#313131',
    display: 'flex',
    padding: '16px',
    alignItems: 'center'
  }
}));

//
const MyIconButton = withStyles({
  root: {
    backgroundColor: 'none',
    color: '#525252',
    '&:hover': {
      backgroundColor: '#0F9095'
    }
  },
})(IconButton);
//

export default function ProjectCard(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={classes.root}>
      <Typography className={classes.cardHeaderText} component="span">
        {props.name}
      </Typography>
      <CardMedia
        className={classes.media}
        image={'images/portfolio/' + props.image}
        title="Project screenshot"
      />
      <CardContent className={classes.cardContentStyle}>
        <Typography className={classes.cardContentText} component="span">
          {props.description}
        </Typography>
        <Typography variant="body2" component="p">
          {props.technologies}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        {/* <MyIconButton aria-label="Github icon">
          <GitHubIcon style={{ fontSize: 20 }} />
        </MyIconButton> */}
        <Typography className={classes.root}>
          <Link href={props.link} target="_blank" rel="noopener">
            <MyIconButton aria-label="Link icon">
              <LinkIcon style={{ fontSize: 20 }} />
            </MyIconButton>
          </Link>
        </Typography>
      </CardActions>
    </Card>
  );
}
